.callback {
    height: 100vh;
    display: flex;
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.callback__logo-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.callback__logo {
    width: 30%;
}

.callback__text {
    color: white;
}