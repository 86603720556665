.entry-form {
    display: flex;
    flex-direction: column;
    border-radius: 5%;
    background-color: rgba(149, 205, 249, 0.95);
    flex-grow: 1;
    gap: 3%;
}

.entry-form__logo-row {
    display: flex;
    justify-content: center;
}

.entry-form__logo {
    width: 14em;
    height: 8em;
    margin-top: 1em;
}

@media (min-width: 576px) and (orientation: landscape) { 
    .entry-form {
        gap: 0%
    }

    .entry-form__logo {
        height: 8em;
        margin-top: 0em;
    }

    .entry__row-spacer {
        flex-grow: 0;
    }

    .entry__form-row {
        justify-content: center;
        flex-grow: 1;
    }

    .entry__column-spacer {
        flex-grow: 1;
    }
 }

 @media (min-width: 1200px) and (orientation: landscape) { 
    .entry-form {
        background-color: rgba(210, 234, 525, 0.3);
    }
 }
