.footer {
    grid-area: footer;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.footer__header-row {
    display: flex;
    width: 100%;
    justify-content: center;
    color: white;
    font-size: 1.15em;
}

.footer__courses-row {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 1%;
}

.footer__side-row {
    display:flex;
    justify-content: right;
    color: white;
    font-size: 1em;
    margin-right: 4%;
}

.side-row__all:hover{
    color: #074b80;
    cursor: pointer;
}