.payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2%;
}

.payment__option-row{
    display: flex;
    gap: 2em;
    margin-top: 2em;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 0.8rem;
}

 .payment__back {
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.payment__logo-row {
    display: flex;
    justify-content: center;
}

.payment__close-row {
    right: 0;
    position: absolute;
}

.payment__header-description--mobile {
    color: white;
}

.payment__back-large {
    display: none;
}

.payment__header-logo {
    justify-content: center;
    width: 40%;
}

.payment__header-title {
    display: none;
}

.payment__header-description {
    display: none;
    color: white;
}

@media (min-width: 576px){
    .payment__header-logo {
        width: 20%;
    }

    .payment__option-row{
        margin-top: 0em;
    }
}

@media (min-width: 576px) and (orientation: landscape) {
    .payment__option-row{
        width: 100%;
        flex-wrap: nowrap;
    }

    .payment__back {
        display: none;
    }

    .payment__back-large {
        display: block;
        pointer-events: none;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
}

@media (min-width: 768px){
    .payment__header-logo {
        width: 30%;
    }

    .payment__header-title {
        display: block;
        font-size: 2em;
        font-weight: 1000;
        color:white;
    }

    .payment__option-row{
        margin-top: 15%;
    }

    .payment {
        gap: 4%;
    }

    .payment__header-description {
        display: block;
        margin-top: 1%;
        font-size: 1.5em;
    }
}

@media (min-width: 768px) and (orientation: landscape) {
    .payment__header-title {
        display: none;
    }
    
    .payment__header-description {
        display: block;
        font-size: 1.2em;
        font-weight: 500;
        color:white;
    }

    .payment__option-row{
        margin-top: 0%;
    }

    .payment__header-logo {
        width: 20%;
    }

    .payment__back {
        display: none;
    }

    .payment__back-large {
        display: block;
        pointer-events: none;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
    }

    .payment__header-description--mobile {
        display: none;
    }

    .payment__header-description {
        display: block;
        margin-top: 2%;
    }
}

@media (min-width: 768px) and (orientation: portrait) {

    .payment {
        height: 100vh;
        gap: 0.1%
    }

    .payment__option-row{
        margin-top: 0%;
    }

    .payment__header-logo {
        width: 30%;
    }

    .payment__option-row{
        font-size: 1rem;
        margin-top: 10%;
        flex-grow: 0.3;
        flex-basis: 50%;
    }

    .payment__header-description--mobile {
        display: none;
    }
}

@media (min-width: 992px){
    .payment__header-logo {
        width: 20%;
    }

    .payment__option-row{
        margin-top: 5%;
    }
}

@media (min-width: 992px) and (orientation: portrait){
    .payment {
        height: 100vh;
        gap: 2%;
    }

    .payment__option-row{
        font-size: 1rem;
        margin-top: 5%;
        flex-grow: 0.3;
        flex-basis: 50%;
    }
}

@media (min-width: 1200px){

    .payment {
        height: 100vh;
        gap: 1.2%;
    }

    .payment__header-logo {
        width: 15%;
    }

    .payment__header-title {
        display: block;
    }
    
    .payment__header-description {
        display: block;
        margin-top: 2%;
    }

    .payment__option-row{
        font-size: 1.2rem;
        flex-basis: 40%;
        margin-top: 1%;
    }
}
