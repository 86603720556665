.option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: rgba(236, 233, 252);
    padding: 4%;
    gap: 2em;
    flex-basis: 80%;
}

.option__title {
    font-size: 2.3em;
    font-weight: 500;
}

.option__price {
    font-size: 1.6em;
}

.option__description-row {
    display: flex;
    justify-content: center;
}

.option__description {
    font-size: 0.8em;
    list-style-type: none;

    & ul {list-style-type: none;}
}

.option__description ul > li{
    margin-left: 2em;
}

.option__information-row {
    display: flex;
    justify-content: start;
    width: 100%;
}

.option__information {
    flex-grow: 0.5;
    font-size: 0.7em;
}

.option__button-row {
    display: flex;
    justify-content: center;
}

.option__submit-button {
  border: none;
  border-radius: 10px;
  color: black;
  background-color: #f2b84d;
  padding: 12px 30px;
  text-align: center;
  text-decoration: none;
  font-size: 1.1em;
  font-weight: 550;
  margin: 4px 2px;
  cursor: pointer;
}

@media (min-width: 576px) and (orientation: landscape) {
    .option {
        flex-basis: auto;
    }
}


@media (min-width: 992px) and (orientation: portrait){
    .option {
        flex-basis: auto;
        flex-grow: 1;
    }
}

@media (min-width: 1200px){

    .option {
        flex-grow: 0.05;
    }
}