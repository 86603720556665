.accountpop {
    display: flex;
    flex-direction: column;
    padding: 5%;
    gap:1em;
}

.accountpop > button {
    background-color: #56b0f5;
    font-size: 0.9em;
    color: white;
    border:none;
    border-radius: 5px;
    padding:2%;
}

.accountpop > button:hover {
    background-color:#86c6f8;
}

.accountpop__details-row {
    display: flex;
    gap: 1em;
}

.accountpop__details-col {
    display: flex;
    flex-direction: column;
}

.accountpop__details-row > img {
    width: 45px;
    height: auto;
}

.accountpop__details-col > p {
    font-size: 0.8em;
}

.accountpop__image:hover {
    cursor: pointer;
}