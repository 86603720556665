.course {
    width: 20%;
}

.course__content {
    height: 60%;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(240, 237, 247, 0.5);
    background-color: rgb(184,220,250,0.65);
    border-radius: 40px;
    gap:1.7em;
}

.course__content p {
    width: 100%;
    word-wrap: break-word;
}

.course__icon-row {
    display: flex;
    justify-content: center;
    gap:1em;
}

.course__icon {
    width: 15%;
}

.course__icon:hover{
    width: 17%;
    cursor: pointer;
}

.course__content{
    font-size: 1.1em;
    color: white;
}

@media (max-width: 1366px) and (orientation: landscape) {
    .course__icon {
        width: 20%;
    }
    .course__content{
        font-size: 0.9em;
        color: white;
    }
}