.verification {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%);
}

.verification__top-spacer {
    flex-grow: 0.2;
}

.verification__middle-spacer {
    flex-grow: 0.07;
}

.verification__logo {
    width: 10%;
}

.verification__mail-icon {
    width: 10%;
}

.verification__text-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.verification__text-col > h1, h3 {
    color: white;
}

.verification__text-col > h1 {
    color: white;
    margin-bottom: 1em;
    font-size: 1em;
}

.verification__text-col > h3 {
    margin-bottom: 1%;
}

.verification__text-col > button {
    height: 3.2em;
    padding: 0 1em;
    margin-top: 1em;
    border: none;
    appearance: none;
    background-color: #806ee9;
    border-radius: 5px;
    border-style: none;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
}

.verification__logo-row {
    display: flex;
    justify-content: center;
}