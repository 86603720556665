.settings__delete-button {
    background-color: white;
    border-radius: 10px;
    padding:2%;
    border:none;
}
.settings__delete-button:hover {
    background-color: #ff7878;

}

.settings__delete-button img {
    width: 9%;
    height: auto;
}

.settings__title {
    width: 100%;
}

.settings__title-row {
    display: flex;
    gap: 3%;
    align-items: center;
}

.settings__title-row > img {
    display: block;
    width: 7%;
    height: auto;
}

.settings__title-input {
    font-size: .6em;
    width: 80%;
}

.delete-button__row {
    display: flex;
    gap: 3%;
    align-items: center;
    font-size: 0.9em;
}

.savesettingschanges {
    background-color:#32cd91;
    border:none;
    font-size: 0.9em;
}

.settings__delete-now {
    border:none;
    font-size: 0.9em;
}

.savesettingschanges:hover {
    background-color: #4ad39e;
    border: none;
}

.closesettings {
    background-color: #a598ef ;
    border: none;
    font-size: 0.9em;
}

.closesettings:hover {
    background-color: #beb5f4 ;
    border: none;
    font-size: 0.9em;
}


.penicon:hover{
    cursor: pointer;
}

.settings_course-favorite > img {
    width: 9%;
    height: auto;
}

.settings_course-favorite > img {
    &:hover {
        cursor: pointer;
    }
}
.settings_course-favorite {
    gap: 3%;
    font-size: 0.9em;
    color: black;
    background-color: white;
    border:none;
}