.new-course {
    width: 20%;
}

.new-course__content {
    height: 60%;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(240, 237, 247, 0.5);
    background-color: rgb(240, 237, 247, 0.5);
    border-radius: 40px;
}

.new-course__icon {
    width: 27%;
    cursor: pointer;
}

.new-course__icon:hover{
    background-color: #fcd354 ;
    border-radius: 10px;
}
.new-course__form {
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 0.8em;
    color: white;
}

.new-course__form > input {
    height: 3em;
    border-radius: 10px;
    border-style: none; 
    padding: 1.5em;
    font-size: 0.8em;

    
}

.new-course__submit {
    display: flex;
    justify-content: center;
    gap: 2em;
    font-size: 0.9em;
}

.new-course__submit-create {
    background-color: #32cd91;
    padding:4%;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1em;



}
.new-course__submit-create:hover {
    background-color: #4ad39e;
}

.new-course__submit-cancel {
    background-color: #988aed ;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1em;
    padding: 4%;
}

.new-course__submit-cancel:hover {
    background-color: #a598ef;
}
