
.overview {
    min-width: 17%;
    min-height: 20%;
    max-width: 18%;
}

.overview__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(240, 237, 247, 0.5);
    background-color: rgb(184,220,250,0.4);
    border-radius: 40px;
    gap:1.7em;
    font-size: 1em;
    color: white;
    padding-left:0.5em;
    padding-right:0.5em;
    height: 100%;
}

.overview__content p {
    width: 100%;
    word-wrap: break-word;
}

.overview__icon-row {
    display: flex;
    justify-content: center;
    gap:1em;
}

.overview__icon {
    max-width: 15%;
}

.overview__settings-icon {
    max-width: 100%;
}

.overview__icon:hover{
    cursor: pointer;
}


.overview__settings-container {
    width: 15%;
}

.overview__settings-toast {
    position: absolute;
    width: 20%;
    max-width: none;
}

@media (max-width: 1366px) and (orientation: landscape) {
    .overview__icon {
        max-width: 20%;
    }
    .overview__content {
        font-size: 0.8em;
    
    }
}