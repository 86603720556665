.actions__container {
    grid-area: header;
    display: flex;
    justify-content: end;
}

.actions {
    padding-top: 4%;
    padding-right: 4%;
    display: flex;
    gap: 0.6em; 
}

.actions__icon {
    width: 1.8em;
    cursor: pointer;
    margin-top: 0.9em;
    margin-right:0.4em;
}