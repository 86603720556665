.login {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 2em;
    height: 100%;
}

.login__description-text {
    color: white;
    font-weight: 600;
}

.login__trial-description {
    color: white;
    justify-content: center;
    display: flex;
}

.login__button-row{
    display: flex;
    justify-content: center;
}

.login__button-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    gap: 1em;
}

.login__button {
    height: 3em;
    width: 12em;
    border: none;
    appearance: none;
    background-color: #2699f3;
    border-radius: 5px;
    border-style: none;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.9em;
    line-height: normal;
}

.login__button:hover {
    background-color: #56aff6;
}

.login__text {
    display: flex;
    justify-content: center;
    background-color: none;
}

.login__signup-activation {
    border: none;
    appearance: none;
    background-color: #fff;
    border-style: none;
    text-align: left;
    color: blue;
    cursor: pointer;
}

.login__signup-button {
    height: 3em;
    width: 12em;
    border: none;
    appearance: none;
    background-color: #806ee9;
    border-radius: 5px;
    border-style: none;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.9em;
    line-height: normal;
}

.login__signup-button:hover {
    background-color: #9e90ee;
}


.login__trialinfo > p {
    font-size: 0.8em;
    font-weight: 120;
}

@media (min-width: 576px) and (orientation: landscape) { 
    .login {
        gap: 0.5em;
    }
 }

 @media (min-width: 768px) { 
    .login {
        font-size: 1.3rem;
        gap: 10%;
    }
 }

 @media (min-width: 768px) and (orientation: landscape) { 
    .login {
        font-size: 1.1rem;
        gap: 5%;
    }
 }

 @media (min-width: 992px) { 
    .login {
        font-size: 1.8rem;
        gap: 10%;
    }
 }

 @media (min-width: 992px) and (orientation: landscape) { 
    .login {
        font-size: 1.3rem;
        gap: 10%;
    }
 }

 @media (min-width: 1200px) and (orientation: landscape) { 
    .login {
        font-size: 1.4rem;
        gap: 15%;
    }

    .login__trial-description {
        font-size: 0.6em;
        justify-content: center;
        display: flex;
    }
 }











  









  




