.dna__container {
    height: 84%;
    width: 60%;

    border-radius: 60px;
}

.dna {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.dna__chart {
    width: 100%;
    height: 100%;
}

canvas {
    max-width: 100%;
    max-height: 100%;
}