.account-details {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.account-details__button-subscriptions {
    background-color: #2699f3;
    color:white;
    border-radius: 10px;
    padding:2%;
    border:none;
    font-size: 0.9em;
}

.account-details__button-subscriptions:hover {
    background-color: #56b0f5;
}

.account-details__button-help {
    background-color: #10d5b5;
    color:white;
    border-radius: 10px;
    padding:2%;
    border:none;
    font-size: 0.9em;
}

.account-details__button-help:hover {
    background-color: #23eecd ;
}

.account-details__button-contact {
    background-color: #10d5b5;
    color:white;
    border-radius: 10px;
    padding:2%;
    border:none;
    font-size: 0.9em;
}

.account-details__button-contact:hover {
    background-color: #23eecd ;
}

.privacy_settings {
    font-weight: 300;
    font-size: x-small;
}

.closesettings {
    font-size: x-small;
    max-width:30%;
    
}