.name-filter__toggle {
    background-color: #3a89fb;
    padding:1em;
    font-size: 0.8em;
    justify-content: center;
    display: flex;
    color: white;
    border-radius: 15px;
    text-decoration: none;
    flex-wrap: nowrap;
}

.name-filter__toggle:hover {
    background-color: #4891fb;
}

.filterwidth {
    min-width: 8%;
    max-width: 10%;
}

.arrowdown{
    width: 8%;
}

.name-filter__btn-row {
    display: flex;
    gap: 5%;
    justify-content: center;
}