.allcourses__view {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to top, #94d4ea 0%, #86c6f8 100%);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
}

p {
    margin-bottom: 0;
}


.allcourses {
    padding-top: 3em;
    height: 100%;
    display: grid;
    grid-template-columns: 5% 37.5% 37.5% 20%;
    grid-template-rows: 10% 8% 2% 5% 25% 50%;
    grid-template-areas: 
      "head head head head"
      ". filter filter ."
      ". . . ."
      ". tag tag ."
      ". courses courses diff"
      ". courses courses diff";
}

.allcourses__filter {
    grid-area: filter;
    display: flex;
    gap: 2%;
    justify-content: left;
    margin-top: 1em; 
    align-items: end;
}

.allcourses__overview {
    grid-area: courses;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    align-content: flex-start;
    z-index: 1;
    margin-top: 1em;
    row-gap: 1.8em;
    column-gap: 1.8em;
}

.allcourses__diff {
    grid-area: diff;
    margin-top: 1em; 
    position: relative;
}

.allcourses__header {
    grid-area: head;
    display: flex;
    justify-content: center;
}

.allcourses__tags {
    grid-area: tag;
    display: flex;
    gap: 0.5em;
}

.wave {
    background: rgb(255 255 255 / 25%);
    border-radius: 1000% 1000% 0 0;
    position: fixed;
    width: 200%;
    height: 6em;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.wave:nth-of-type(2) {
    bottom: -1.25em;
    animation: wave 18s linear reverse infinite;
    opacity: 0.8;
}

@keyframes wave {
    2% {
        transform: translateX(1);
    }

    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(1);
    }
}

.headerallcourses {
    font-size: 1.2em;
}

.filtertag {
    background-color: rgb(86, 176, 245, 0.7) !important; 
    color: white;
    border-radius: 10px !important;
    font-weight: 580; 
}

.closetag{
    color: white;
}

.allcourses__favorite-filter {
    background-color: #3a89fb;
    padding:1em;
    font-size: 0.8em;
    justify-content: center;
    display: flex;
    color: white;
    border:none;
    border-radius: 15px;
    text-decoration: none;
    flex-wrap: nowrap;
    width: 10%;
    gap: 5%;
    align-items: center;

    &:hover {
        background-color: #4891fb;
    }
}

.closetagcontainer {
    justify-content: center;
}

.filterwidth {
    width: 10%;
}

@media (max-width: 1366px) and (orientation: landscape) {
    .filterwidth {
        min-width: 18%;
    }
}