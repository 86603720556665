.entry {
    height:100vh;
    display: flex;
    flex-direction: column;
}

.entry__background {
    pointer-events: none;
    position: absolute;
    max-width: 100%;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.entry__background--large {
    display: none;
}

.entry__form-row {
    display: flex;
    flex-grow: 1;
}

.entry__row-spacer {
    flex-grow: 1;
}

.entry__column-spacer {
    flex-grow: 1.3;
}


@media (min-width: 576px) and (orientation: landscape) { 
    .entry__row-spacer {
        flex-grow: 1;
    }

    .entry__form-row {
        flex-grow: 1;
    }

    .entry__column-spacer {
        flex-grow: 1.2;
    }
 }

@media (min-width: 768px) { 
    .entry__row-spacer {
        flex-grow: 0;
    }

    .entry__form-row {
        flex-grow: 2;
        margin: 3em;
    }

    .entry__column-spacer {
        flex-grow: 1;
    }
 }

 @media (min-width: 768px) and (orientation: landscape) { 
    .entry__form-row {
        flex-grow: 2;
        margin: 0em;
    }

    .entry__row-spacer {
        flex-grow: 1;
    }
 }

@media (min-width: 992px) { 
    .entry__row-spacer {
        flex-grow: 0.5;
    }

    .entry__form-row {
        flex-grow: 1;
        padding-left: 0em;
    }

    .entry__column-spacer {
        flex-grow: 1;
    }
 }

 @media (min-width: 992px) and (orientation: landscape) { 
    .entry__row-spacer {
        flex-grow: 1;
    }

    .entry__form-row {
        flex-grow: 2;
        padding-left: 0em;
    }

    .entry__column-spacer {
        flex-grow: 1;
    }
 }

@media (min-width: 1200px) { 
    .entry__row-spacer {
        flex-grow: 1;
    }

    .entry__form-row {
        flex-grow: 1;
        padding-left: 0em;
    }

    .entry__column-spacer {
        flex-grow: 1;
    }

    .entry__background--large {
        display: block;
        pointer-events: none;
        position: absolute;
        max-width: 100%;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .entry__background {
        display: none;
    }
 }

 @media (min-width: 1200px) and (orientation: landscape) { 

    #entry__row-spacer--left {
        flex-grow: 0.3;
    }

    .entry__row-spacer {
        flex-grow: 3;
    }

    .entry__form-row {
        flex-grow: 1;
        padding-left: 0em;
    }

    .entry__column-spacer {
        flex-grow: 1.8;
    }

    .entry__background--large {
        display: block;
        pointer-events: none;
        position: absolute;
        max-width: 100%;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .entry__background {
        display: none;
    }
 }