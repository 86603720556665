.side {
    grid-area: side;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.side__actions {
    display: flex;
    flex-direction: column;
    gap: 1.8em;
    border: 1px solid rgb(240, 237, 247, 0.5);
    border-radius: 30px;
    background-color: rgb(231, 244, 254, 0.4);
    padding: 55%;
  
    width: 5em;
    height: 20em;
    align-items: center;
}

.action__icon {
    width: 2.3em;
    cursor: pointer;

}
.action__icon:hover {
width: 2.5em;
}
